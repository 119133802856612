import { IOrder } from '../../../../app/entities/Order'
import { removeFreeOrderReason } from '../../schedule/components/api'
import { ISchedulerStateProps } from '../../schedule/components/schedule-modal/components/container-modal/initialData'
import { fetchData } from './fetch'

async function removeFreeOrderReasonAndCalculateCosts(
  schedulerState: ISchedulerStateProps,
  setSchedulerState: React.Dispatch<React.SetStateAction<ISchedulerStateProps>>,
  initialStateProps: ISchedulerStateProps,
) {
  const order = await fetchData<IOrder>(
    removeFreeOrderReason(
      schedulerState.orderId,
    ),
  )
  setSchedulerState({
    ...schedulerState,
    freeOrderReason: initialStateProps.freeOrderReason,
  })
}

export default removeFreeOrderReasonAndCalculateCosts
