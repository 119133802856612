import { getBatteryDataReport } from '../../../api'
import { ReportButtonProps } from '../DownloadReportsModal'

const batteryDataButtonsWithDates: ReportButtonProps[] = [
  {
    buttonName: 'Battery Data',
    reportName: 'rpt_batteryData',
    reportCall: getBatteryDataReport,
  },
]

export { batteryDataButtonsWithDates }
