import TextField from '@mui/material/TextField'
import { ChangeEvent, useContext } from 'react'
import { ILocation } from '../../../../../../../app/entities/Location'
import PrimaryCheckbox from '../../../../../global/components/checkbox/Checkbox'
import styles from './styles.module.css'
import { UserContext } from '../../../../../base/components/base-container/BaseContainer'
import hasPermission from '../../../../../global/utils/user/has-permission'
import { PERMISSIONS } from '../../../../../global/constants/permissions'
import RouteCalculatorApiDropDown from '../RouteCalculatorApiDropDown'

export interface ILocationFormProps {
  setLocationFormInfo: Function
  locationFormInfo: Partial<ILocation>
}

export function LocationForm(props: ILocationFormProps) {
  const { user } = useContext(UserContext)
  const {
    name,
    addressStreet,
    addressCity,
    stateAbbr,
    zipCode,
    workDayFrom,
    workDayTo,
    productionMode,
    usingTolls,
    standbyQueueActive,
    lunchDuration,
    travelTimeMax,
    costCenter,
    routeCalculatorApi,
  } = props.locationFormInfo

  function updateLocationForm(e: ChangeEvent<HTMLInputElement>) {
    props.setLocationFormInfo({
      ...props.locationFormInfo,
      [e.target.name]: e.target.value,
    })
  }

  function updateProductionMode(e: ChangeEvent<HTMLInputElement>) {
    props.setLocationFormInfo({
      ...props.locationFormInfo,
      productionMode: e.target.checked,
    })
  }
  function updateUsingTolls(e: ChangeEvent<HTMLInputElement>) {
    props.setLocationFormInfo({
      ...props.locationFormInfo,
      usingTolls: e.target.checked,
    })
  }
  function updateStandbyQueueActive(e: ChangeEvent<HTMLInputElement>) {
    props.setLocationFormInfo({
      ...props.locationFormInfo,
      standbyQueueActive: e.target.checked,
    })
  }

  // permissions variables
  const isLocationInfoEditDisabled = !hasPermission(user).edit(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.LOCATION_INFO.NAME,
  )
  const isLocationInfoViewable = hasPermission(user).view(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.LOCATION_INFO.NAME,
  )

  const isWorkdayInfoEditDisabled = !hasPermission(user).edit(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.WORKDAY_INFO.NAME,
  )
  const isWorkdayInfoViewable = hasPermission(user).view(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.WORKDAY_INFO.NAME,
  )

  const isRoutingInfoEditDisabled = !hasPermission(user).edit(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.ROUTING.NAME,
  )
  const isRoutingInfoViewable = hasPermission(user).view(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.ROUTING.NAME,
  )

  const isCostCenterInfoEditDisabled = !hasPermission(user).edit(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.COST_CENTER.NAME,
  )
  const isCostCenterInfoViewable = hasPermission(user).view(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.COST_CENTER.NAME,
  )

  const isProductionModeButtonDisabled = !hasPermission(user).edit(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.PRODUCTION_MODE_BUTTON.NAME,
  )
  const isProductionModeButtonViewable = hasPermission(user).view(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.PRODUCTION_MODE_BUTTON.NAME,
  )
  const isUsingTollsDisabled = !hasPermission(user).edit(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.USING_TOLLS_BUTTON.NAME,
  )
  const isUsingTollsViewable = hasPermission(user).view(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.USING_TOLLS_BUTTON.NAME,
  )

  const isStandbyQueueButtonDisabled = !hasPermission(user).edit(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.STANDBY_QUEUE_BUTTON.NAME,
  )
  const isStandbyQueueButtonViewable = hasPermission(user).view(
    PERMISSIONS.LOCATIONS.CATEGORY,
    PERMISSIONS.LOCATIONS.ENTRIES.STANDBY_QUEUE_BUTTON.NAME,
  )

  return (
    <div className={styles.locationFormContainer}>
      {isLocationInfoViewable && (
        <div className={styles.locationFormFlexBox}>
          <div className={styles.locationFormTitle}>Location</div>
          <TextField
            onChange={updateLocationForm}
            name='name'
            value={name || ''}
            className={styles.wideTextField}
            label='Name'
            disabled={isLocationInfoEditDisabled}
          />
          <TextField
            onChange={updateLocationForm}
            name='addressStreet'
            value={addressStreet || ''}
            className={styles.wideTextField}
            label='Street'
            disabled={isLocationInfoEditDisabled}
          />
          <TextField
            onChange={updateLocationForm}
            name='addressCity'
            value={addressCity || ''}
            className={styles.wideTextField}
            label='City'
            disabled={isLocationInfoEditDisabled}
          />
          <div className={styles.doubleFieldContainer}>
            <TextField
              onChange={updateLocationForm}
              name='stateAbbr'
              value={stateAbbr || ''}
              className={styles.narrowTextField}
              label='State Abbr'
              disabled={isLocationInfoEditDisabled}
            />
            <TextField
              onChange={updateLocationForm}
              name='zipCode'
              value={zipCode || ''}
              className={styles.narrowTextField}
              label='Zip'
              disabled={isLocationInfoEditDisabled}
            />
          </div>
        </div>
      )}
      {isWorkdayInfoViewable && (
        <div className={styles.locationFormFlexBox}>
          <div className={styles.workdayLabel}>Workday</div>
          <div className={styles.doubleFieldContainer}>
            <TextField
              onChange={updateLocationForm}
              name='workDayFrom'
              value={workDayFrom || ''}
              className={styles.narrowTextField}
              label='Workday From'
              disabled={isWorkdayInfoEditDisabled}
            />
            <TextField
              onChange={updateLocationForm}
              name='workDayTo'
              value={workDayTo || ''}
              className={styles.narrowTextField}
              label='Workday To'
              disabled={isWorkdayInfoEditDisabled}
            />
          </div>
          <div className={styles.doubleFieldContainer}>
            <TextField
              onChange={updateLocationForm}
              name='lunchDuration'
              value={lunchDuration || ''}
              className={styles.narrowTextField}
              label='Lunch Duration'
              disabled={isWorkdayInfoEditDisabled}
            />
          </div>
        </div>
      )}
      {isRoutingInfoViewable && (
        <div className={styles.locationFormFlexBox}>
          <div className={styles.workdayLabel}>Routing</div>
          <div className={styles.doubleFieldContainer}>
            <TextField
              onChange={updateLocationForm}
              name='travelTimeMax'
              value={travelTimeMax || ''}
              className={styles.narrowTextField}
              label='Max Drive Time'
              disabled={isRoutingInfoEditDisabled}
            />
            <RouteCalculatorApiDropDown
              onChange={updateLocationForm}
              name='routeCalculatorApi'
              label='Route Calculator API'
              className={styles.narrowTextField}
              initialValue={routeCalculatorApi ?? ''}
            />
          </div>
        </div>
      )}
      {isCostCenterInfoViewable && (
        <div className={styles.locationFormFlexBox}>
          <div className={styles.workdayLabel}>Cost Center</div>
          <TextField
            className={styles.narrowTextField}
            label='Cost Center'
            name='costCenter'
            onChange={updateLocationForm}
            type='number'
            value={costCenter || ''}
            disabled={isCostCenterInfoEditDisabled}
          />
        </div>
      )}
      <div className={styles.checkboxContainer}>
        {isProductionModeButtonViewable && (
          <PrimaryCheckbox
            checked={productionMode as boolean}
            name='productionMode'
            onChange={updateProductionMode}
            label='Production Mode'
            className={styles.locationCheckboxSmallText}
            disabled={isProductionModeButtonDisabled}
          />
        )}
        {isUsingTollsViewable && (
          <PrimaryCheckbox
            checked={usingTolls as boolean}
            name='usingTolls'
            onChange={updateUsingTolls}
            label='Using Tolls'
            className={styles.locationCheckboxSmallText}
            disabled={isUsingTollsDisabled}
          />
        )}
        {isStandbyQueueButtonViewable && (
          <PrimaryCheckbox
            checked={standbyQueueActive as boolean}
            name='standbyQueue'
            onChange={updateStandbyQueueActive}
            label='Standby Queue'
            className={styles.locationCheckboxSmallText}
            disabled={isStandbyQueueButtonDisabled}
          />
        )}
      </div>
    </div>
  )
}
