/* These should be the same as the entries in the Roles table.
 *
 * This isn't the best way to reference specific Roles as object
 * ids can be changed, but we have no other way to fulfil stakeholder
 * requirements when they want to reference specific role in code.
 */
const OBJECT_IDS = {
  SALES: 'sales',
}

export { OBJECT_IDS }
