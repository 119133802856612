import { useEffect, useState, useContext } from 'react'
import styles from './styles.module.css'
import TextField from '@mui/material/TextField'
import { useParams } from 'react-router-dom'
import { WoDetailContext } from '../../../context/context'
import { fetchData } from '../../../../../global/utils/fetch'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { ToastNotificationContext } from '../../../../../global/context/toast-context/ToastNotificationContext'
import { IOrder } from '../../../../../../../app/entities/Order'
import { overwriteOrderNote } from '../../../../../queues/components/api'
import { STATUSES } from '../../../../../global/constants/order-status'

interface IWorkOrderNotesProps {
  isReadOnly: boolean
}

export default function WorkOrderNotes({ isReadOnly }: IWorkOrderNotesProps) {
  const { id } = useParams()
  const [note, setNote] = useState<string>('')
  const [cancelledOnSiteNote, setCancelledOnSiteNote] = useState<string>('')

  const { data: order } = useContext(WoDetailContext)
  const { toastStatus, setToastStatus } = useContext(ToastNotificationContext)

  useEffect(() => {
    if (order.note) {
      setNote(order.note)
    }
  }, [order])

  useEffect(() => {
    const orderVehicle = order.orderVehicles?.[0]
    const orderIsCancelledOnSite = order.status === STATUSES.cancelled_onsite
    
    const {
      cosReason1,
      cosReason2,
      cosReason3,
      cosNote: orderCosNote,
    } = orderVehicle || {}

    const hasCancelledOnSiteReason = cosReason1 || cosReason2 || cosReason3

    const shouldShowCancelledOnSiteNote = orderIsCancelledOnSite && hasCancelledOnSiteReason

    if (!shouldShowCancelledOnSiteNote) {
      return setCancelledOnSiteNote('')
    }
    
    const cosSummary = `Cancelled on-site: ${cosReason1} / ${cosReason2}${cosReason3 ? ` / ${cosReason3}` : ''}\n\nNote: ${orderCosNote}`

    setCancelledOnSiteNote(cosSummary)
  }, [order])

  const handleEditNote = async () => {
    try {
      await fetchData<IOrder>(overwriteOrderNote(id ?? '', note))
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        severity: 'success',
        message: 'Note has successfully saved!',
      })
    } catch (err) {
      console.error(err)
      setToastStatus({
        ...toastStatus,
        isOpen: true,
        severity: 'error',
        message: 'Error saving note!',
      })
    }
  }

  return (
    <>
      <div>
        <div className={styles.heading}>Work Order Note</div>
        <div className={styles.container}>
          <TextField
            sx={{ width: '100%', height: '100%' }}
            InputLabelProps={{ shrink: true }}
            multiline
            rows={10}
            label='Edit Note'
            value={note}
            disabled={isReadOnly}
            onChange={(e) => {
              setNote(e.target.value)
            }}
          />
          <button
            className={styles.saveButton}
            onClick={handleEditNote}
            disabled={isReadOnly}
          >
            Save Note
          </button>
          {cancelledOnSiteNote && (
              <TextField
                sx={{ width: '100%', height: 'auto', marginTop: '16px' }}
                InputLabelProps={{ shrink: true }}
                multiline
                rows={6}
                label='Cancelled On Site Note'
                value={cancelledOnSiteNote}
                disabled={true}
                onChange={(e) => {
                  setNote(e.target.value)
                }}
              />
            )}
        </div>
      </div>
    </>
  )
}
