export function getBraintreeToken(orderId: string) {
	return {
		method: "GET",
		url: `${process.env.REACT_APP_ORIGIN}/braintree/client-token/${orderId}`,
	}
}

export const createPaymentMethod = (orderId: string, nonce: string) => {
	return {
		method: "POST",
		body: nonce,
		url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/payment-method`,
	}
}

export function getFreeOrderReasons() {
	return {
		method: "GET",
		url: `${process.env.REACT_APP_ORIGIN}/free_order_reasons`,
	}
}


export function setShouldRequestPaymentAfterScheduling(orderId: string, shouldRequestPaymentAfterScheduling: boolean) {
	return {
		method: "PUT",
		url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/should-request-payment-after-scheduling/${shouldRequestPaymentAfterScheduling}`,
	}
}

export function payOrderInvoice(orderId: string) {
	return {
		method: 'GET',
		url: `${process.env.REACT_APP_ORIGIN}/orders/${orderId}/pay-invoice`,
	}
}
