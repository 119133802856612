import { useContext, useEffect, useState } from 'react'
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom'
import { useAuthenticator } from '@aws-amplify/ui-react'
import prodLogo from '../../../assets/images/trmi-logos/prod.png'
import trainLogo from '../../../assets/images/trmi-logos/train.png'
import qaLogo from '../../../assets/images/trmi-logos/qa.png'
import devLogo from '../../../assets/images/trmi-logos/dev.png'
import ephemeralLogo from '../../../assets/images/trmi-logos/ephemeral.png'
import localLogo from '../../../assets/images/trmi-logos/local.png'
import styles from './styles.module.css'
import { getWeekNumber } from './api'
import useFetch from '../../../global/hooks/useFetchHook'
import ToastNotification from '../../../global/components/toast-alert/ToastNotification'
import { ToastNotificationContext } from '../../../global/context/toast-context/ToastNotificationContext'
import {
  IS_ENV_DEV,
  IS_ENV_EPHEMERAL,
  IS_ENV_LOCAL,
  IS_ENV_QA,
  IS_ENV_TRAIN,
} from '../../../global/constants/env'
import { UserContext } from '../base-container/BaseContainer'

interface LinksProps {
  links: Array<NavLinkProps>
}

const logo = (() => {
  if (IS_ENV_TRAIN) {
    return trainLogo
  }
  if (IS_ENV_QA) {
    return qaLogo
  }
  if (IS_ENV_DEV) {
    return devLogo
  }
  if (IS_ENV_EPHEMERAL) {
    return ephemeralLogo
  }
  if (IS_ENV_LOCAL) {
    return localLogo
  }

  return prodLogo
})()

function Links(props: LinksProps) {
  const { links } = props

  const { signOut } = useAuthenticator((context) => [context.signOut])

  const logOut = () => {
    signOut()
  }

  const { pathname } = useLocation()
  const [selected, setSelected] = useState(pathname)

  useEffect(() => {
    setSelected(`/${pathname.split('/')[1]}`)
  }, [pathname])

  return (
    <div className={styles.linksHolder}>
      {links.map((link: NavLinkProps, idx: number) => {
        const linkToBase = link.to.toString().split('/')[1]
        const selectedBase = selected.toString().split('/')[1]
        const isTabHighlighted = linkToBase === selectedBase
        return (
          <NavLink
            className={`${styles.link} ${isTabHighlighted ? `${styles.chosen} font--bold` : ''}`}
            key={idx}
            to={link.to}
          >
            {link.title}
          </NavLink>
        )
      })}
      <div
        onClick={logOut}
        className={styles.link}
      >
        Log Out
      </div>
    </div>
  )
}

interface NavDrawerProps {
  links: Array<NavLinkProps>
}

function NavDrawer(props: NavDrawerProps) {
  const { toastStatus, handleClose } = useContext(ToastNotificationContext)
  const { user } = useContext(UserContext)
  const [weekNumber, setWeekNumber] = useState(0)
  const { links } = props
  const { data } = useFetch<any>(getWeekNumber())

  useEffect(() => {
    if (data) {
      setWeekNumber(data.weekNumber)
    }
  }, [data])

  return (
    <>
      <ToastNotification
        severity={toastStatus.severity}
        open={toastStatus.isOpen}
        handleClose={handleClose}
        message={toastStatus.message}
      />
      <div className='navDrawerMain'>
        <div className={styles.navDrawerContainer}>
          <div className={styles.header}>
            <div className={styles.logoHolder}>
              <img
                src={logo}
                className={styles.logo}
                alt='logo'
              />
            </div>
            <div className={styles.headerInfo}>
              <div className='title font--bold font--large'>CS Portal</div>
              {data && <div className='weekNumber'>Week {weekNumber}</div>}
            </div>
          </div>
          <div>
            <Links links={links} />
          </div>
          {user && (
            <div className={styles.userContainer}>
              <div className={styles.userName}>{user?.name || ''}</div>
              <div className={styles.userInfo}>{user?.email || ''}</div>
              <div className={styles.userInfo}>{user?.role?.name || ''}</div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default NavDrawer
