/**
 * @param date
 * @returns H:MM am/pm MM/DD
 */
export function formatDateTime(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: '2-digit',
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour12: true,
  }

  return new Intl.DateTimeFormat('en-US', options).format(date)
}
