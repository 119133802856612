import React from 'react'
import styles from '../styles.module.css'
import logo from '../../../assets/images/tire-rack-dot-com-logo-285x65.png'

export default function ErrorScreen() {
  return (
    <div className={styles.customerSchedulePage}>
      <div className={styles.errorContainer}>
        <div>
          <img
            src={logo}
            alt='logo'
            className={styles.logo}
          />
        </div>
        <h1 className={styles.errorHeader1}>Oops! Something went wrong.</h1>
        <div>
          Please contact our customer support team. They're ready and willing to
          help you sort this out as quickly as possible.{' '}
        </div>
        <h2>Customer Support Details:</h2>
        <div>You can reach our customer support team at:</div>
        <div>(833)-702-8764</div>
        <div>Mon-Fri, 8:00 AM - 8:00 PM ET</div>
        <div>Sat, 9:00 AM - 4:00 PM ET</div>
        <div>We appreciate your patience and understanding.</div>
      </div>
    </div>
  )
}
