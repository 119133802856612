import styles from './styles.module.css'
import { createContext, useEffect, useMemo, useState } from 'react'
import NavDrawer from '../nav-drawer/NavDrawer'
import linksData from '../../../global/tsx/links'
import NavLinkPropsWithElement from '../../../global/types/NavLinkPropsWithElement'
import { RequireAuth } from '../../../global/components/auth/RequireAuth'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import ModalContent from '../../../work-orders/components/work-order-detail/components/main/ModalContent'
import { Login } from '../../../login/Login'
import CreditCardUpdateMain from '../../../customer-update-cc-page/components/CreditCardUpdateMain'
import TipsPage from '../../../tips-page/components/TipsPage'
import CustomerSchedulerMain from '../../../customer-scheduler/components/CustomerSchedulerMain'
import JmulletTools from '../../../jmullet-tools/components/JmulletTools'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { fetchData } from '../../../global/utils/fetch'
import { getOrPossiblyCreateUserByUsername } from './api'
import { IUser } from '../../../../../app/entities/User'
import QueuesMain from '../../../queues/components/main/QueuesMain'
import { MODES } from '../../../global/constants/scheduler'
import OrderTracker from '../../../order-tracker/components/main/OrderTracker'
import { IRole } from '../../../../../app/entities/Role'
import UserManagementMain from '../../../user-management/main/UserManagementMain'
import hasPermission from '../../../global/utils/user/has-permission'
import { PERMISSIONS } from '../../../global/constants/permissions'
import { VanContextProvider } from '../../../global/context/van-context/VanContext'
import DashboardsMain from '../../../dashboards/main/DashboardsMain'
import {
  DASHBOARD_KEYS,
  DASHBOARD_PATHS,
} from '../../../global/constants/dashboards'
import UserError from '../../../user-error/UserError'
import LogRocket from 'logrocket'
import { OBJECT_IDS } from '../../../global/constants/roles'

interface IUserContext {
  user: IUser | null
  refetchUser: () => void
}

export const UserContext = createContext<IUserContext>({
  user: null,
  refetchUser: () => {},
})

export const routesThatShouldNotRedirectDueToMissingAuth = [
  /^\/login(\/.*)?$/i,
  /^\/status\/.+$/,
  /^\/schedule-appointment\/.+$/,
  /^\/customer-tip-screen\/.+$/,
  /^\/customer-update-cc\/.+$/,
  /^\/reschedule-appointment\/.+$/,
]

const routesNotDisplayingNavDrawer = [
  ...routesThatShouldNotRedirectDueToMissingAuth,
  /^\/user-error$/,
]

function BaseContainer() {
  const navigate = useNavigate()

  const { user: amplifyUser } = useAuthenticator((context) => [context.user])
  const [userRole, setUserRole] = useState<IRole>({} as IRole)
  const [userData, setUserData] = useState<IUser | null>(null)

  const links: Array<NavLinkPropsWithElement> = useMemo(
    () =>
      linksData.filter((elem) => {
        const { permissionInfo } = elem
        const { category, name } = permissionInfo || {}

        const allowPermissionFound =
          !category ||
          !name ||
          hasPermission({
            role: { permissions: userRole.permissions || [] },
          } as IUser).allow(category, name)

        return allowPermissionFound
      }),
    [userRole.permissions],
  )

  const { pathname } = useLocation()
  const shouldDisplayNavDrawer = !routesNotDisplayingNavDrawer.some((route) =>
    route.test(pathname),
  )

  async function refetchUser() {
    if (
      amplifyUser.challengeName === 'NEW_PASSWORD_REQUIRED' &&
      !(amplifyUser as any)?.signInUserSession?.idToken
    ) {
      return
    }
    const amplifyUsername = amplifyUser.username ?? ''
    const userResponse = await fetchData<Response>(
      getOrPossiblyCreateUserByUsername(amplifyUsername),
    )

    const userDoesNotExist = userResponse.status === 204
    const otherErrorOccurred = userResponse.status !== 200 && !userDoesNotExist

    if (userDoesNotExist) {
      navigate('/user-error')
      return
    } else if (otherErrorOccurred) {
      console.error(userResponse)
      throw new Error('Unexpected response when getting user info')
    }

    const user = await userResponse.json()
    setUserData(user)
    setUserRole(user.role || ({} as IRole))

    LogRocket.identify(user.objectId, {
      name: user.name,
      email: user.email,

      role: user.role?.name || 'No Role',
    })

    if (
      user.role.objectId === OBJECT_IDS.SALES &&
      !window.location.href.endsWith('tr-sales')
    ) {
      navigate('/tr-sales')
    }
  }

  useEffect(() => {
    //fetch user role & save to local storage
    if (amplifyUser) {
      refetchUser()
    }
  }, [amplifyUser])

  return (
    <UserContext.Provider value={{ user: userData, refetchUser }}>
      <VanContextProvider>
        <div className={styles.baseContainerWrapper}>
          {shouldDisplayNavDrawer && <NavDrawer links={links}></NavDrawer>}
          <Routes>
            <Route
              path={'/jmullet-tools'}
              element={
                <RequireAuth>
                  <JmulletTools />
                </RequireAuth>
              }
            />
            <Route
              path={'/user-error'}
              element={<UserError />}
            />
            <Route
              path={'/schedule-appointment/:id'}
              element={<CustomerSchedulerMain mode={MODES.CUSTOMER} />}
            />
            <Route
              path={'/reschedule-appointment/:id'}
              element={
                <CustomerSchedulerMain mode={MODES.CUSTOMER_RESCHEDULE} />
              }
            />
            {hasPermission({
              role: { permissions: userRole.permissions || [] },
            } as IUser).allow(
              PERMISSIONS.NAV_BAR.CATEGORY,
              PERMISSIONS.NAV_BAR.ENTRIES.VIEW_QUEUES.NAME,
            ) && (
              <>
                <Route
                  path='/queues/reschedule'
                  element={
                    <RequireAuth>
                      <QueuesMain queueType={'reschedule'} />
                    </RequireAuth>
                  }
                >
                  <Route
                    path=':id'
                    element={<ModalContent />}
                  />
                </Route>
                <Route
                  path='/queues/tire-rack-data'
                  element={
                    <RequireAuth>
                      <QueuesMain queueType={'tire-rack-data'} />
                    </RequireAuth>
                  }
                >
                  <Route
                    path=':id'
                    element={<ModalContent />}
                  />
                </Route>
              </>
            )}

            {links.map((link) => (
              <Route
                path={String(link.to)}
                element={link.element}
                key={link.title}
              >
                {link.nestedRoutes &&
                  link.nestedRoutes.map((nestedRoute, idx) => {
                    // this ensures that the nested routes don't complain about missing keys
                    // without the need to explicitly set a key on each nested route
                    return (
                      <Route
                        {...nestedRoute.props}
                        key={idx}
                      />
                    )
                  })}
              </Route>
            ))}
            <Route
              path={'/login/*'}
              element={<Login />}
            />
            <Route
              path={'/customer-tip-screen/:id'}
              element={<TipsPage />}
            />
            <Route
              path={'/customer-update-cc/:id'}
              element={<CreditCardUpdateMain />}
            />
            <Route
              path={'/status/:id'}
              element={<OrderTracker />}
            />
            {hasPermission({
              role: { permissions: userRole.permissions || [] },
            } as IUser).allow(
              PERMISSIONS.NAV_BAR.CATEGORY,
              PERMISSIONS.NAV_BAR.ENTRIES.VIEW_USER_MANAGEMENT.NAME,
            ) && (
              <Route
                path='/user-management/roles'
                element={
                  <RequireAuth>
                    <UserManagementMain tabType={'roles'} />
                  </RequireAuth>
                }
              >
                <Route
                  path=':id'
                  element={<ModalContent />}
                />
              </Route>
            )}
            {hasPermission({
              role: { permissions: userRole.permissions || [] },
            } as IUser).allow(
              PERMISSIONS.NAV_BAR.CATEGORY,
              PERMISSIONS.NAV_BAR.ENTRIES.VIEW_DASHBOARDS.NAME,
            ) && (
              <Route
                path={DASHBOARD_PATHS.schedulingPerformance}
                element={
                  <RequireAuth>
                    <DashboardsMain
                      tabType={DASHBOARD_KEYS.schedulingPerformance}
                    />
                  </RequireAuth>
                }
              >
                <Route
                  path=':id'
                  element={<ModalContent />}
                />
              </Route>
            )}
          </Routes>
        </div>
      </VanContextProvider>
    </UserContext.Provider>
  )
}

export default BaseContainer
