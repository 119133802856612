export const COLUMN_DEFINITIONS = {
  SCHEDULING_PERFORMANCE: {
    total_scheduled_orders: [
      'The total number of scheduled orders on this day.',
    ].join('\n'),
    integrated_scheduler_rate: [
      'Percent of customers who choose us',
      'and used the Integrated Scheduler',
    ].join('\n'),
    drivetime_zeroes: [
      'The number of customers who were',
      'shown zero available dates due to',
      'being too far away.',
    ].join('\n'),
    schedule_full_zeroes: [
      'The number of customers who were',
      'shown zero available dates because',
      'there were no open slots available -',
      'and it was not due to drivetime max.',
    ].join('\n'),
    average_schedule_lead_time: [
      'The average number of days between',
      `the scheduled date and the`,
      'appointment date.',
    ].join('\n'),
    seven_day_percent_booked: [
      'The percent of appointment slots that',
      'are full within the next 7 days.',
    ].join('\n'),
  },
  FUTURE_PERCENT_BOOKED: {
    d2a: {
      general: `"Days to Availability" - the number of days till the first available appointment.`,
      minimum: `Days till first appointment if drivetime is 0sec away.`,
      average: `Days till first appointment if the drivetime is the location's 30day average drivetime away.`,
      maximum: `Days till first appointment if the drivetime is the location's maximum drivetime away.`,
    },
  },
}
