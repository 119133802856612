import React, { useRef, useState, Suspense, useEffect } from 'react'
import styles from '../styles.module.css'
import logo from '../../../../src/assets/images/tire-rack-dot-com-logo-285x65.png'
import PrimaryButton from '../../../global/components/buttons/primary-button/PrimaryButton'
import CircularProgress from '@mui/material/CircularProgress'
import { SUPPORT_PHONE_NUMBER } from '../../../global/notifications'
import formatPrice from '../../../global/utils/format-price'
import { calculateCost, getIsOrderPaid } from './api'
import { fetchData } from '../../../global/utils/fetch'
import { useFetchNoEffect } from '../../../global/hooks/useFetchNoUseEffectHook'
import { ICalculateCostResponse } from '../../../global/types/IAdjustCalculateCostResponse'
import { STATUSES } from '../../../global/constants/order-status'
import moment from 'moment'
import ComplianceLinks from '../../../global/components/compliance-links/ComplianceLinks'

const ERR_BRAINTREE_INSTANCE_NOT_FOUND = [
  'Attempt Payment Failed: No Braintree Instance Found.',
  `Please contact our customer support team at ${SUPPORT_PHONE_NUMBER} for assistance.`,
].join(' ')

interface IUpdateCCFormProps {
  id: string
  data: any
}
const BraintreeUpdatePayment = React.lazy(
  () => import('../Braintree/BraintreeUpdatePayment'),
)

function UpdateForm(props: IUpdateCCFormProps) {
  const { id, data: order } = props

  const [isLoading, setIsLoading] = useState(false)
  const [statusText, setStatusText] = useState({
    isError: false,
    message: '',
  })

  const [shouldShowPaymentInfo, setShouldShowPaymentInfo] = useState(true)
  const [isOrderPaid, setIsOrderPaid] = useState(false)

  // There should never be more than one quote invoice
  // const price = formatPrice(order.invoices?.find((invoice: any) => invoice.type === TYPES.QUOTE).totalAmountCharged)
  const brainTreeRef = useRef<any>(null)
  const [affirmativeDisabled, setAffirmativeDisabled] = useState(false)
  const [isCardOnFile, setIsCardOnFile] = useState(false)

  const [successMessageElement, setSuccessMessageElement] = useState<any>(
    <div className={styles.paymentInfo}></div>,
  )

  /*
   * -1 prevents the generic message from being shown on initial render before the price is fetched
   * Also, if the customer see's the price as $-1 after they attempt a payment, they will know something went wrong
   * and hopefully contact support to ensure there was no double charge
   */
  const [priceString, setPriceString] = useState(formatPrice(-1))
  const customerName = `${order.customer.firstName} ${order.customer.lastName}`
  const appointmentDate = moment
    .utc(order.timeSlot?.fromTime)
    .format('M/D/YYYY')

  const { handleFetchData, response } =
    useFetchNoEffect<ICalculateCostResponse>(calculateCost(id))

  function getPaymentSuccessElement() {
    return (
      <div className={styles.paymentInfo}>
        Your payment was processed successfully in the amount of {priceString}.
        <br />
        <br />
        Thank you for choosing Tire Rack Mobile Installation!
      </div>
    )
  }

  function getPaymentFailureMsg() {
    return [
      `Your payment for the amount of ${priceString} failed to process.`,
      'Please check your card information and try again or use an alternate card.',
    ].join(' ')
  }
  function setAffirmativeDisabledCallback(affirmativeDisabled: boolean) {
    setAffirmativeDisabled(affirmativeDisabled)
  }
  function isCardOnFileCallback(isCardOnFile: boolean) {
    setIsCardOnFile(isCardOnFile)
  }

  async function attemptPaymentHandler() {
    if (brainTreeRef.current) {
      try {
        const paymentSubmittedForSettlement =
          await brainTreeRef.current.attemptPaymentUpdateAndSubmitSettlement()

        if (paymentSubmittedForSettlement) {
          setSuccessMessageElement(getPaymentSuccessElement())
          setIsOrderPaid(true)
        } else {
          setStatusText({
            isError: true,
            message: getPaymentFailureMsg(),
          })
        }
      } catch (e: any) {
        setStatusText({
          isError: true,
          message: e.message,
        })
        setIsLoading(false)
      }
    } else {
      setStatusText({
        isError: true,
        message: ERR_BRAINTREE_INSTANCE_NOT_FOUND,
      })
    }
  }

  async function checkIsOrderPaid() {
    setIsOrderPaid(await fetchData<boolean>(getIsOrderPaid(id)))
  }

  useEffect(() => {
    handleFetchData()
    checkIsOrderPaid()
    setSuccessMessageElement(
      <div className={styles.paymentInfo}>
        Thank you for choosing Tire Rack Mobile Installation. There is no
        payment due at this time.
        <br />
        <br />
        We hope to see you again soon!
      </div>,
    )
  }, [])

  useEffect(() => {
    if (response.data) {
      setPriceString(formatPrice(response.data.estimatedTotal))
    }
  }, [response.data])

  useEffect(() => {
    let tempShouldShowPaymentInfo = !(
      isOrderPaid ||
      order.status === STATUSES.cancelled ||
      Boolean(order.freeOrderReason) ||
      priceString === '$0.00'
    )

    setShouldShowPaymentInfo(tempShouldShowPaymentInfo)
  }, [priceString, isOrderPaid])

  return (
    <div className={styles.updateCardContainer}>
      {isLoading && (
        <div className={styles.overlay}>
          <div className={styles.progressContainer}>
            <CircularProgress size={80} />
          </div>
        </div>
      )}

      <div>
        <img
          src={logo}
          alt='logo'
          className={styles.logo}
        />
      </div>
      {shouldShowPaymentInfo ? (
        <div>
          <div className={styles.header}>Enter Payment Information</div>
          <div className={styles.orderInfo}>
            <div>{customerName}</div>
            <div>Appointment Date: {appointmentDate}</div>
            <div>Amount Due: {priceString}</div>
          </div>
          <div>
            <Suspense fallback={<CircularProgress />}>
              <BraintreeUpdatePayment
                orderId={id}
                ref={brainTreeRef}
                setAffirmativeDisabled={setAffirmativeDisabledCallback}
                setIsLoading={setIsLoading}
                isCardOnFileCallback={isCardOnFileCallback}
              />
            </Suspense>
          </div>
          <div className={styles.complianceAndButtonContainer}>
            <div className={styles.complianceLinks}>
              <ComplianceLinks />
            </div>
            <div className={styles.buttonContainer}>
              {order.status === STATUSES.completed ? (
                <PrimaryButton
                  buttonName='Attempt Payment'
                  /*
                   * isOrderPaid in disabled prevents user from charging themselves twice since the
                   * user does not move to a different screen after the payment is processed.
                   */
                  disabled={!isCardOnFile || affirmativeDisabled}
                  onClick={attemptPaymentHandler}
                />
              ) : (
                <PrimaryButton
                  buttonName='Update'
                  disabled={isLoading || affirmativeDisabled}
                  onClick={() => {
                    if (brainTreeRef.current) {
                      brainTreeRef.current.attemptPaymentUpdate()
                    }
                  }}
                />
              )}
            </div>
          </div>
          <div
            className={styles.statusMessages}
            style={{ color: statusText.isError ? '#D70000' : '' }}
          >
            {statusText.message}
          </div>
        </div>
      ) : (
        successMessageElement
      )}
    </div>
  )
}

export default UpdateForm
