import React from "react"
import styles from "../styles.module.css"
import logo from "../../../assets/images/tire-rack-dot-com-logo-285x65.png"

interface ITipConfirmationProps {
	amount: string
}


export default function TipConfirmation(props: ITipConfirmationProps) {
	const { amount } = props
	return (
		<div className={styles.tipsContainer}>
			<div>
				<img
					src={logo}
					alt="logo"
					className={styles.logo}
				/>
			</div>
			<div className={styles.header}>Thank You!</div>
			<div className={styles.text}>
				Your <b>${amount}</b> tip has been sent to your technician!
			</div>
			<div className={styles.text}>
				Please call us if you need to change your tip amount <a href="tel:833-702-8764">833-702-8764</a>
			</div>
		</div>
	)
}
