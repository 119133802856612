import { useEffect } from 'react'
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { useNavigate, useLocation } from 'react-router'
import { Auth } from 'aws-amplify'
import ciscoDuoLogo from '../assets/images/cisco-duo-logo.png'
import googleLogo from '../assets/images/google-logo.png'
import tireRackDotComLogo from '../assets/images/tire-rack-dot-com-logo.png'
import trmiLogo from '../assets/images/trmi-logos/prod.png'
import styles from './styles.module.css'
import { IDENTITY_PROVIDERS } from '../global/constants/identity-providers'

export function Login() {
  const { route } = useAuthenticator((context) => [context.route])
  const location = useLocation()
  const navigate = useNavigate()

  let from = location.state?.from?.pathname || '/'

  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true })
    }
  }, [route, navigate, from])

  async function handleDuoLogin() {
    try {
      await Auth.federatedSignIn({ customProvider: IDENTITY_PROVIDERS.DUO })
      console.log('Duo login redirect successful')
    } catch (error) {
      console.error('Duo login redirect failed:', JSON.stringify(error))
    }
  }

  async function handleGoogleLogin() {
    try {
      await Auth.federatedSignIn({ customProvider: IDENTITY_PROVIDERS.GOOGLE })
      console.log('Google login redirect successful')
    } catch (error) {
      console.error('Google login redirect failed:', JSON.stringify(error))
    }
  }

  return (
    <div className={styles.authWrapper}>
      <div className={styles.authWrapperInner}>
        <div className={styles.authWrapperRight}>
          <div className={styles.buttonContainer}>
            <img
              className={styles.tireRackDotComLogo}
              src={tireRackDotComLogo}
              alt='TireRack.com Logo'
            />
            <button
              className={styles.federatedLoginButton}
              onClick={handleDuoLogin}
            >
              <img
                className={styles.ciscoDuoLogo}
                src={ciscoDuoLogo}
                alt='Cisco Duo Logo'
              />
              Login with Duo
            </button>
          </div>

          <div className={styles.buttonContainer}>
            <img
              className={styles.trmiLogo}
              src={trmiLogo}
              alt='TRMI Logo'
            />
            <button
              onClick={handleGoogleLogin}
              className={styles.federatedLoginButton}
            >
              <img
                className={styles.googleLogo}
                src={googleLogo}
                alt='Google Logo'
              />
              Login with Google
            </button>
          </div>
        </div>

        <div>or</div>

        {/* username/password login via the user pool */}
        <Authenticator className={styles.authenticator} />
      </div>
    </div>
  )
}
