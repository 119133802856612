import { ChangeEventHandler } from 'react'
import { MenuItem, TextField } from '@mui/material'
import { ROUTE_CALCULATOR_API_OPTIONS } from '../../../../global/constants/route'

interface IRouteCalculatorApiDropDownProps {
  initialValue: string
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  name: string
  label?: string
  className?: string
}

function RouteCalculatorApiDropDown({
  initialValue,
  onChange,
  name,
  label,
  className,
}: IRouteCalculatorApiDropDownProps) {
  const menuItems = Array.from(Object.values(ROUTE_CALCULATOR_API_OPTIONS)).map(
    (option, idx) => {
      return (
        <MenuItem
          key={idx}
          value={option}
        >
          {option}
        </MenuItem>
      )
    },
  )
  return (
    <TextField
      label={label ?? 'Route Calculator API'}
      select
      name={name}
      value={initialValue}
      onChange={onChange}
      className={className}
    >
      {menuItems}
    </TextField>
  )
}

export default RouteCalculatorApiDropDown
